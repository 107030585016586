import { HandbookDataRow } from '@emrm/handbooks/types';
import { City } from '@emrm/common/types/api';

import { BlockOption } from '../block-option.type';
import { User } from '@emrm/users/types';

export interface ModificationTable {
	_id: string;
	city: City;
	month: HandbookDataRow;
	year: HandbookDataRow;
	blocking: BlockOption;
	createdAt: string;
	metadata: ModificationTableMetadata[];
	data: ModificationTableRow[];
}

export interface ModificationTableMetadata {
	_id: string;
	sort: number;
	name: string;
	rus_name: string;
	table_id: string;
	handbook_code: string;
	handbook_column_name: string;
	handbook_filter_name: string;
	type_column: {
		_id: string;
		name: CellType;
	};
}

export interface ModificationTableRow {
	_id: string;
	sort: number;
	table_id: string;
	parent_row_id: string;
	depth_level: number;
	cells: ModificationTableCell[];
}

export interface ModificationTableCell {
	_id: string;
	sort: number;
	row_id: string;
	metadata_id: string;
	access: boolean;
	value: ModificationTableCellValue;
}

export const CellType = {
	Handbook: 'handbook',
	String: 'string',
	Number: 'int',
	File: 'file',
	User: 'user',
} as const;

export type CellType = (typeof CellType)[keyof typeof CellType];

export type ModificationTableCellValue =
	| HandbookDataRow
	| User
	| DocumentCellValue
	| string
	| number;

export type DocumentCellValue = string;
