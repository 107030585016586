import {
	ModificationTableRow,
	ModificationTableCell,
} from './modification-table.type';

export interface ModificationTableContextMenu {
	scope: ContextMenuScope;
	row: ModificationTableRow;
	cell: ModificationTableCell;
}

export const ContextMenuScope = {
	Row: 'row',
	Cell: 'cell',
} as const;

export type ContextMenuScope =
	(typeof ContextMenuScope)[keyof typeof ContextMenuScope];
